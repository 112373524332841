export const NOT_SPECIFIED_OFFER_TYPE = null;
export const EBON_OFFER_TYPE = 13;
export const FLASHCASH_OFFER_TYPE = 14;

export const UNKNOWN_ACTIVATION = "UNKNOWN";
export const ONLINE_ACTIVATION = "ONLINE";
export const OFFLINE_ACTIVATION = "OFFLINE";
export const ONLINE_AND_OFFLINE_ACTIVATION = "ONLINE_AND_OFFLINE";

export const OFFER_ACTIVATION_TYPES = {
  [ONLINE_ACTIVATION]: "En ligne",
  [OFFLINE_ACTIVATION]: "Hors ligne",
  [ONLINE_AND_OFFLINE_ACTIVATION]: "En ligne et hors ligne",
};

export const ONLINE_ACTIVITY = "ONLINE";
export const OFFLINE_ACTIVITY = "OFFLINE";
export const EBON_ACTIVITY = "EBON";
export const OTHER_ACTIVITY = "NONE";

export const OFFER_ACTIVITY_TYPE = {
  [ONLINE_ACTIVITY]: "En ligne",
  [OFFLINE_ACTIVITY]: "En magasin",
  [EBON_ACTIVITY]: "En bon d'achat",
};

export const FLASHCASH_OFFER_TYPE_FILTER = "FLASHCASH";

export const OFFER_TYPE_FILTER = {
  ...OFFER_ACTIVITY_TYPE,
  [FLASHCASH_OFFER_TYPE_FILTER]: "Flashcash",
};

export const ROUNDING_HALF_UP = "ROUNDING_HALF_UP";
export const FLOOR = "FLOOR";
export const CEILING = "CEILING";

export const CASHBACK_ROUNDING_MODE = {
  [ROUNDING_HALF_UP]: "Plus proche",
  [FLOOR]: "Inférieur",
  [CEILING]: "Supérieur",
};

export const CASHBACK_ROUNDING_ACCURACIES = [0.5, 0.1, 0.01];

export const getActivationType = offer => {
  if (offer?.type === EBON_OFFER_TYPE) {
    return EBON_ACTIVITY;
  }

  if (!offer?.isOffline) {
    return ONLINE_ACTIVITY;
  }

  return OFFLINE_ACTIVITY;
};
