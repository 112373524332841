/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";

function useDidUpdate(callback, observer) {
  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      callback();
    }
  }, observer);
}
export default useDidUpdate;
