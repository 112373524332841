import { createContext, useContext, useMemo } from "react";

import { NOT_REQUESTED } from "@constants/api";
import {
  PLATFORM,
  PLATFORM_GET,
  SALES_REPRESENTATIVE,
  SALES_REPRESENTATIVE_GET,
} from "@constants/roles";
import useApi from "@utils/hooks/useApi";
import useDidUpdate from "@utils/hooks/useDidUpdate";
import useUser from "@utils/hooks/useUser";

const GlobalDataContext = createContext();

export function GlobalDataProvider({ children }) {
  const { user, hasOneOfRequiredRoles } = useUser();
  const { state: salesRepresentatives, setParams: setSalesRepresentativeParams } = useApi(
    "/sales-representative",
    {},
    { waitFirstCall: true },
  );

  const { state: platforms, setParams: setPlatformParams } = useApi(
    "/platform",
    {},
    { waitFirstCall: true },
  );

  const { state: languages, setParams: setLanguageParams } = useApi(
    "/master/language",
    {},
    { waitFirstCall: true },
  );

  useDidUpdate(() => {
    if (
      hasOneOfRequiredRoles([SALES_REPRESENTATIVE, SALES_REPRESENTATIVE_GET]) &&
      salesRepresentatives.status === NOT_REQUESTED
    ) {
      setSalesRepresentativeParams({});
    }
    if (hasOneOfRequiredRoles([PLATFORM, PLATFORM_GET]) && platforms.status === NOT_REQUESTED) {
      setPlatformParams({});
    }
    if (languages.status === NOT_REQUESTED) {
      setLanguageParams({});
    }
  }, [user]);

  const value = useMemo(() => {
    return {
      salesRepresentatives,
      platforms,
      languages,
    };
  }, [salesRepresentatives, platforms, languages]);

  return <GlobalDataContext.Provider value={value}>{children}</GlobalDataContext.Provider>;
}

export const useGlobalData = () => useContext(GlobalDataContext);

export default useGlobalData;

export const GlobalDataConsumer = GlobalDataContext.Consumer;
