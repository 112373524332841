// url

const LBP_API = process.env.REACT_APP_LBP_API;
const LBP_DOMAIN = process.env.REACT_APP_LBP_DOMAIN;
const CLASSIC_API = process.env.REACT_APP_API;
export const API = window.location.origin === LBP_DOMAIN ? LBP_API : CLASSIC_API;

// methods
export const GET = "GET";
export const POST = "POST";
export const DELETE = "DELETE";
export const PUT = "PUT";

// status
export const NOT_REQUESTED = "NOT_REQUESTED";
export const LOADING = "LOADING";
export const SUCCESS = "SUCCESS";
export const FAILURE = "FAILURE";

// errors
export const RESOURCE_DOES_NOT_EXIST = "RESOURCE_DOES_NOT_EXIST";
