import { useEffect, useMemo, useState } from "react";

import { CONFIG_BY_ACTIVITY_TYPE } from "@context/partner/constants";

import { GET, SUCCESS } from "@constants/api";
import { EBUYCLUB } from "@constants/application";
import api from "@utils/api";
import useApi from "@utils/hooks/useApi";
import useApplication from "@utils/hooks/useApplication";
import useDidUpdate from "@utils/hooks/useDidUpdate";
import useUser from "@utils/hooks/useUser";

function PartnerProvider({ activityType, children }) {
  const { endpoint, Context } = CONFIG_BY_ACTIVITY_TYPE[activityType];

  const [currentPartner, setCurrentPartner] = useState(null);
  const [transferEbcPartnerId, setTransferEbcPartnerId] = useState(null);
  const [partnersData, setPartnersData] = useState([]);

  const { user, hasAllApplicationAccess } = useUser();
  const { applicationId, multiMode } = useApplication();

  const addPartner = newPartner =>
    setPartnersData(oldPartnersData => [...oldPartnersData, newPartner]);

  const reloadOfflinePartner = async ebcId => {
    const { ok, payload } = await api({
      endpoint: `/offline-partner/${ebcId}`,
      method: GET,
      params: {
        applicationId,
      },
    });

    if (ok) {
      setPartnersData(oldPartnersData =>
        oldPartnersData.map(partner => (partner.ebcId === ebcId ? payload : partner)),
      );
    }
  };

  const {
    state: { status, data },
    setParams,
    reset,
  } = useApi(endpoint, { applicationId }, { waitFirstCall: true });

  const nameSortedPartnerData = useMemo(
    () => [...partnersData].sort((p1, p2) => p1.name.localeCompare(p2.name)),
    [partnersData],
  );

  const partnerOptions = useMemo(
    () =>
      nameSortedPartnerData.map(partner => ({
        value: partner.id,
        label: partner.name,
        activeEbon: partner.activeEbon,
      })),
    [nameSortedPartnerData],
  );

  const partnerOptionsUsingEbcId = useMemo(
    () =>
      nameSortedPartnerData.map(partner => ({
        value: partner?.ebcId || partner?.ebcOnlinePartnerId,
        label: partner.name,
        activeEbon: partner.activeEbon,
        ebcId: partner.ebcId,
        couponRestrictionLevel: partner.couponRestrictionLevel,
      })),
    [nameSortedPartnerData],
  );

  useEffect(() => {
    if (user) {
      if (multiMode || (applicationId === null && hasAllApplicationAccess)) {
        setParams({ applicationId: EBUYCLUB });
      } else if (applicationId !== null) {
        setParams({ applicationId });
      } else {
        reset();
      }
    }
  }, [user, applicationId, multiMode]);

  useDidUpdate(() => {
    if (status === SUCCESS) {
      setPartnersData([...data]);

      if (transferEbcPartnerId) {
        const newPartner = partnersData.find(partner => partner.id === transferEbcPartnerId);
        setCurrentPartner(newPartner);
        setTransferEbcPartnerId(null);
      }
    }
  }, [status]);

  const partnersByEbcId = useMemo(
    () => Object.fromEntries(partnersData.map(partner => [partner.ebcId, partner])),
    [partnersData],
  );

  const value = useMemo(() => {
    return {
      partner: currentPartner,
      setPartner: setCurrentPartner,
      addPartner,
      partners: { status, data: partnersData },
      setTransferEbcPartnerId,
      reloadOfflinePartner,
      partnerOptions,
      partnerOptionsUsingEbcId,
      partnersByEbcId,
    };
  }, [
    currentPartner,
    setCurrentPartner,
    addPartner,
    status,
    partnersData,
    setTransferEbcPartnerId,
    reloadOfflinePartner,
    partnerOptions,
    partnerOptionsUsingEbcId,
    partnersByEbcId,
  ]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export default PartnerProvider;
