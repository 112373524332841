import { useRef } from "react";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  CircularProgress,
} from "@material-ui/core";
import { PropTypes } from "prop-types";

import useDidUpdate from "@utils/hooks/useDidUpdate";

function ConfirmationDialog({ open, onClose, title, content, contentText, loading }) {
  // Used to prevent double-clicking and triggering of multiple onClose callbacks
  const hasClicked = useRef(false);

  useDidUpdate(() => {
    if (open) {
      hasClicked.current = false;
    }
  }, [open]);

  const handleClose = value => () => {
    if (value && !hasClicked.current) {
      hasClicked.current = true;
      onClose(value);
    } else if (!value) {
      onClose(value);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose(false)}>
      <DialogTitle>{title}</DialogTitle>
      {(content || contentText) && (
        <DialogContent>
          <DialogContentText>{contentText}</DialogContentText>
          {content}
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleClose(false)} color="primary">
          Non
        </Button>
        {loading ? (
          <CircularProgress color="primary" />
        ) : (
          <Button onClick={handleClose(true)} color="primary" autoFocus variant="contained">
            Oui
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.node,
  contentText: PropTypes.string,
  loading: PropTypes.bool,
};

export default ConfirmationDialog;
