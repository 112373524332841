export const EBUYCLUB = 0;
export const CASINO_MAX = 59;
export const MAX_ARKEA = 67;
export const CYRANA_BE_FR = 77;
export const CYRANA_BE_NL = 80;
export const HUBSIDE_BE_FR = 91;
export const HUBSIDE_BE_NL = 95;
export const LA_BANQUE_POSTALE = 110;
export const BFM = 127;

export const MULTILINGUAL_APPLICATIONS = [CYRANA_BE_FR, CYRANA_BE_NL, HUBSIDE_BE_FR, HUBSIDE_BE_NL];

export const ALL_APPLICATIONS_ID = -1;
export const ALL_MB_ID = -2;
export const MULTI_APPLICATION_IDS = [ALL_APPLICATIONS_ID, ALL_MB_ID];

export const EBUYCLUB_URL = process.env.REACT_APP_EBUYCLUB_URL;

export const MB_UPDATED_RESOURCE_ORIGIN = 69;

// Holdings
export const SFAM = 2;
