import { createContext, useContext, useState, useCallback, useMemo } from "react";

import ConfirmationDialog from "@components/ConfirmationDialog";

const ConfirmDialogContext = createContext();

export function ConfirmDialogProvider({ children }) {
  const [options, setOptions] = useState(null);
  const [promiseCallback, setPromiseCallback] = useState(null);

  const requestConfirm = useCallback(
    confirmOptions =>
      new Promise((resolve, reject) => {
        setOptions(confirmOptions);
        setPromiseCallback({ resolve, reject });
      }),
    [],
  );

  const handleClose = useCallback(
    confirmResult => {
      if (confirmResult) {
        promiseCallback.resolve();
      } else {
        promiseCallback.reject();
      }
      setPromiseCallback(null);
      setOptions(null);
    },
    [promiseCallback],
  );

  const value = useMemo(() => {
    return {
      requestConfirm,
    };
  }, [requestConfirm]);

  return (
    <ConfirmDialogContext.Provider value={value}>
      {children}
      <ConfirmationDialog open={!!options} onClose={handleClose} {...(options || [])} />
    </ConfirmDialogContext.Provider>
  );
}

export const useConfirmDialog = () => useContext(ConfirmDialogContext);

export default useConfirmDialog;

export const ConfirmDialogConsumer = ConfirmDialogContext.Consumer;
