import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";

import { navigate } from "@reach/router";

import { FAILURE } from "@constants/api";
import { ALL_APPLICATIONS_ID, MULTI_APPLICATION_IDS } from "@constants/application";
import { SUPER } from "@constants/roles";
import { AUTH_TOKEN } from "@constants/storage";
import useApplication from "@utils/hooks/useApplication";
import useDidUpdate from "@utils/hooks/useDidUpdate";
import { getAuthInfos } from "@utils/jwtParser";
import Storage from "@utils/storage";

const UserContext = createContext();

export function UserProvider({ children }) {
  const [landingPath, setLandingPath] = useState(null);
  const [user, setUser] = useState(null);
  const [redirectedOnAuthInit, setRedirectedOnAuthInit] = useState(null);
  const [temporaryUser, setTemporaryUser] = useState(null);
  const [finishedAuthInit, setFinishedAuthInit] = useState(false);
  const {
    applications,
    handleApplicationChange,
    fetchApplications,
    handleUserAuthenticatedChange,
  } = useApplication();

  const hasSpecificRequiredRole = useCallback(
    requiredRole => !!user && user.roles.includes(requiredRole),
    [user],
  );

  const hasAllRequiredRoles = useCallback(
    requiredRoles =>
      !!user && (user.roles.includes(SUPER) || requiredRoles.every(hasSpecificRequiredRole)),
    [user, hasSpecificRequiredRole],
  );

  const hasOneOfRequiredRoles = useCallback(
    requiredRoles =>
      !!user && (user.roles.includes(SUPER) || !!requiredRoles.find(hasSpecificRequiredRole)),
    [user, hasSpecificRequiredRole],
  );

  const hasMultiApplicationAccess =
    !!user &&
    (user.applicationIds.length > 1 ||
      user.applicationIds.some(id => MULTI_APPLICATION_IDS.includes(id)));

  const hasAllApplicationAccess = !!user && user.applicationIds.includes(ALL_APPLICATIONS_ID);

  const hasSpecificApplicationAccess = useCallback(
    applicationId =>
      !!user && (user.applicationIds.includes(applicationId) || hasAllApplicationAccess),
    [user, hasAllApplicationAccess],
  );

  useDidUpdate(() => {
    if (applications.status === FAILURE && applications.error === "UNAUTHORIZED") {
      handleDisconnection();
    }
  }, [applications.status]);

  useEffect(() => {
    if (window.location.pathname !== "/login") {
      setLandingPath(window.location.pathname + window.location.search);
    } else {
      setLandingPath("/");
    }

    if (user === null) {
      const token = Storage.getJSON(AUTH_TOKEN);
      const infos = getAuthInfos(token);
      // set if can be loaded from the storage
      if (infos.isAuthenticated && !infos.needsPasswordUpdate) {
        fetchApplications();
        if (
          infos.applicationIds.length === 1 &&
          !infos.applicationIds.some(id => MULTI_APPLICATION_IDS.includes(id))
        ) {
          handleApplicationChange(infos.applicationIds[0]);
        }
        setUser(infos);
      } else {
        setRedirectedOnAuthInit(true);
        navigate("/login");
      }
    }
  }, []);

  useEffect(() => {
    if (!finishedAuthInit && landingPath && (user || redirectedOnAuthInit)) {
      setFinishedAuthInit(true);
    }
  }, [user, redirectedOnAuthInit, landingPath]);

  useDidUpdate(() => {
    handleUserAuthenticatedChange(!!user?.isAuthenticated);
  }, [user]);

  const handleConnection = useCallback(
    token => {
      const infos = getAuthInfos(token);

      if (infos.isAuthenticated) {
        Storage.setJSON(AUTH_TOKEN, token);
        if (!infos.needsPasswordUpdate) {
          setTemporaryUser(null);
          fetchApplications();
          if (
            infos.applicationIds.length === 1 &&
            !infos.applicationIds.some(id => MULTI_APPLICATION_IDS.includes(id))
          ) {
            handleApplicationChange(infos.applicationIds[0]);
          }
          setUser(infos);
        } else {
          setTemporaryUser(infos);
        }
      }
    },
    [fetchApplications, handleApplicationChange],
  );

  const handleDisconnection = useCallback(() => {
    Storage.remove(AUTH_TOKEN);
    setUser(null);
    handleApplicationChange(null);
    setLandingPath("/");
  }, [handleApplicationChange]);

  const value = useMemo(() => {
    return {
      user,
      landingPath,
      temporaryUser,
      finishedAuthInit,
      handleDisconnection,
      handleConnection,
      hasAllRequiredRoles,
      hasOneOfRequiredRoles,
      hasMultiApplicationAccess,
      hasAllApplicationAccess,
      hasSpecificRequiredRole,
      hasSpecificApplicationAccess,
    };
  }, [
    user,
    landingPath,
    temporaryUser,
    finishedAuthInit,
    handleDisconnection,
    handleConnection,
    hasAllRequiredRoles,
    hasOneOfRequiredRoles,
    hasMultiApplicationAccess,
    hasAllApplicationAccess,
    hasSpecificRequiredRole,
    hasSpecificApplicationAccess,
  ]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

const useUser = () => useContext(UserContext);
export default useUser;

export const UserConsumer = UserContext.Consumer;
