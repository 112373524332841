import { createContext, useState, useContext, useEffect, useMemo, useCallback } from "react";

import { LOADING, SUCCESS, FAILURE, NOT_REQUESTED } from "@constants/api";
import { APPLICATION_ID } from "@constants/storage";
import api from "@utils/api";
import useApi from "@utils/hooks/useApi";
import Storage from "@utils/storage";

const ApplicationContext = createContext();

const makeInitialState = (keys, status) =>
  keys.reduce(
    (acc, key) => ({
      ...acc,
      [key]: { status, data: [], error: null },
    }),
    {},
  );

const getData = async (key, endpoint, applicationId, callback) => {
  const { ok, payload, message } = await api({
    endpoint,
    params: {
      applicationId: endpoint.includes(applicationId) ? null : applicationId,
    },
  });

  const data = {
    status: ok ? SUCCESS : FAILURE,
    data: payload || [],
    error: ok ? null : message,
  };

  callback(data);
  return { [key]: data };
};

const loadApplicationIdFromLocalStorage = () => {
  const storageValue = Storage.get(APPLICATION_ID);

  if (storageValue !== null) {
    const parsedApplicationId = parseInt(storageValue);

    return parsedApplicationId >= 0 ? parsedApplicationId : null;
  }

  return null;
};

export function ApplicationProvider({ children }) {
  const [userAuthenticated, setUserAuthenticated] = useState(false);
  const [applicationId, setApplicationId] = useState(loadApplicationIdFromLocalStorage());
  const [multiMode, setMultiMode] = useState(false);
  const [hideSelectContext, setHideSelectContext] = useState(false);

  const { state: applications, setParams } = useApi("/application", {}, { waitFirstCall: true });

  const config = useMemo(
    () => ({
      segments: "/segment/byApplication",
      cashbackSettings: "/cashbackSetting/byApplication",
      meaTypes: "/mea/type",
      applicationInfos: `/application/${applicationId}`,
    }),
    [applicationId],
  );

  const [state, setState] = useState(makeInitialState(Object.keys(config), NOT_REQUESTED));

  useEffect(() => {
    if (userAuthenticated && applicationId !== null) {
      refreshApplicationState();
    }
  }, [userAuthenticated, applicationId, setState]);

  const handleApplicationChange = useCallback(id => {
    if (id !== null) {
      Storage.set(APPLICATION_ID, id);
    } else {
      Storage.remove(APPLICATION_ID);
    }

    setApplicationId(id);
  }, []);

  const refreshApplicationState = useCallback(async () => {
    const configKeys = Object.keys(config);

    if (applicationId !== null) {
      setState(makeInitialState(configKeys, LOADING));

      configKeys.map(key =>
        getData(key, config[key], applicationId, data => {
          setState(prevState => ({
            ...prevState,
            [key]: data,
          }));
        }),
      );
    } else {
      setState(makeInitialState(configKeys, NOT_REQUESTED));
    }
  }, [applicationId, config]);

  const fetchApplications = useCallback(() => setParams({}), [setParams]);

  const handleUserAuthenticatedChange = useCallback(
    authenticated => setUserAuthenticated(authenticated),
    [],
  );

  const application = applications.data.find(app => app.id === applicationId);

  const value = useMemo(() => {
    return {
      applicationId,
      application,
      handleApplicationChange,
      refreshApplicationState,
      applications,
      multiMode,
      setMultiMode,
      hideSelectContext,
      setHideSelectContext,
      fetchApplications,
      handleUserAuthenticatedChange,
      ...state,
    };
  }, [
    applicationId,
    application,
    handleApplicationChange,
    refreshApplicationState,
    applications,
    multiMode,
    setMultiMode,
    hideSelectContext,
    setHideSelectContext,
    fetchApplications,
    handleUserAuthenticatedChange,
    state,
  ]);

  return <ApplicationContext.Provider value={value}>{children}</ApplicationContext.Provider>;
}

const useApplication = () => useContext(ApplicationContext);
export default useApplication;

export const ApplicationConsumer = ApplicationContext.Consumer;
