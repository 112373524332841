import { lazy, Suspense, useRef } from "react";

import DateFnsUtils from "@date-io/date-fns";
import { Button, LinearProgress } from "@material-ui/core";
import { StylesProvider, ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Router } from "@reach/router";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import frLocale from "date-fns/locale/fr";
import { SnackbarProvider } from "notistack";

import PartnerProvider from "@context/partner/PartnerProvider";
import GlobalStyle from "@styles/global";
import { muiTheme } from "@styles/theme";

import RedirectRoute from "@components/RedirectRoute";
import { OFFLINE_ACTIVITY, ONLINE_ACTIVITY } from "@constants/cashback";
import { PMI, PMI_GET } from "@constants/roles";
import { ApplicationProvider } from "@utils/hooks/useApplication";
import { ConfirmDialogProvider } from "@utils/hooks/useConfirmDialog";
import { GlobalDataProvider } from "@utils/hooks/useGlobalData";
import { UserConsumer, UserProvider } from "@utils/hooks/useUser";
import { queryClient } from "@utils/query-client";

// Lazy components
const Layout = lazy(() => import("@components/Layout"));
const MultiApplication = lazy(() => import("@components/MultiApplication"));

// Pages
const DailyNewsCurrentSelection = lazy(() => import("@pages/DailyNewsCurrentSelection"));
const ApplicationClientRequest = lazy(() => import("@pages/ApplicationClientRequest"));
const CloseCustomerServiceMemberRequest = lazy(() =>
  import("@pages/CloseCustomerServiceMemberRequest"),
);
const PartnerExclusiveScheduling = lazy(() => import("@pages/PartnerExclusiveScheduling"));
const EbonOrderCreation = lazy(() => import("@pages/EbonOrderCreation"));
const CompetitiveIntelligence = lazy(() => import("@pages/PartnerMode/CompetitiveIntelligence"));
const Mea = lazy(() => import("@pages/Mea"));
const Home = lazy(() => import("@pages/Home"));
const Ebon = lazy(() => import("@pages/Ebon"));
const Login = lazy(() => import("@pages/Login"));
const Export = lazy(() => import("@pages/Export"));
const Member = lazy(() => import("@pages/Member"));
const Backlog = lazy(() => import("@pages/Backlog"));
const Tracking = lazy(() => import("@pages/Tracking"));
const NotFound = lazy(() => import("@pages/NotFound"));
const Purchase = lazy(() => import("@pages/Purchase"));
const Dashboard = lazy(() => import("@pages/Dashboard"));
const EbonOrder = lazy(() => import("@pages/EbonOrder"));
const EbonGlobal = lazy(() => import("@pages/EbonGlobal"));
const Offer = lazy(() => import("@pages/PartnerMode/Offer"));
const AddPurchase = lazy(() => import("@pages/AddPurchase"));
const PictureUpload = lazy(() => import("@pages/GenericPictureUpload"));
const BolPlatformPurchaseImport = lazy(() => import("@pages/BolPlatformPurchaseImport"));
const CashbackRate = lazy(() => import("@pages/CashbackRate"));
const EbonApiStock = lazy(() => import("@pages/EbonApiStock"));
const PlatformClaim = lazy(() => import("@pages/PlatformClaim"));
const Communication = lazy(() => import("@pages/Communication"));
const OnlinePartner = lazy(() => import("@pages/OnlinePartner"));
const PasswordUpdate = lazy(() => import("@pages/PasswordUpdate"));
const OfflinePartner = lazy(() => import("@pages/OfflinePartner"));
const PaymentRequest = lazy(() => import("@pages/PaymentRequest"));
const CommissionBoost = lazy(() => import("@pages/CommissionBoost"));
const FlashCashHistory = lazy(() => import("@pages/FlashcashHistory"));
const DailyNewsGallery = lazy(() => import("@pages/DailyNewsGallery"));
const CatalinaReward = lazy(() => import("@pages/CatalinaReward"));
const PurchaseMassUpdate = lazy(() => import("@pages/PurchaseMassUpdate"));
const PartnerGlobal = lazy(() => import("@pages/PartnerMode/PartnerGlobal"));
const PartnerNameMatching = lazy(() => import("@pages/PartnerNameMatching"));
const ToolbarNotification = lazy(() => import("@pages/ToolbarNotification"));
const DailyNewsPartnerTop = lazy(() => import("@pages/DailyNewsPartnerTop"));
const OnlinePartnerCreation = lazy(() => import("@pages/OnlinePartnerCreation"));
const AccountingInformation = lazy(() => import("@pages/AccountingInformation"));
const MeaMultipleImageUpdate = lazy(() => import("@pages/MeaMultipleImageUpdate"));
const OfflinePartnerCreation = lazy(() => import("@pages/OfflinePartnerCreation"));
const EbuyclubOfferComparison = lazy(() => import("@pages/EbuyclubOfferComparison"));
const EbonGroupAdministration = lazy(() => import("@pages/EbonGroupAdministration"));
const Coupon = lazy(() => import("@pages/Coupon"));
const Gallery = lazy(() => import("@pages/Gallery"));
const EbonAdministration = lazy(() => import("@pages/Ebon/Administration"));
const Faqs = lazy(() => import("@pages/Faqs"));
const JackpotNotifications = lazy(() => import("@pages/JackpotNotifications"));
const Invoicing = lazy(() => import("@pages/Ebon/Invoicing"));
const ECard = lazy(() => import("@pages/Ebon/ECard"));
const EbonBatchOrder = lazy(() => import("@pages/Ebon/EbonBatchOrder"));
const PaymentRequestValidation = lazy(() => import("@pages/PaymentRequestValidation"));
const AlertRecipient = lazy(() => import("@pages/AlertRecipient"));
const Review = lazy(() => import("@pages/Review"));

function App() {
  const notistackRef = useRef();

  const handleSnackbarDismiss = key => () => notistackRef.current.closeSnackbar(key);

  return (
    <>
      <GlobalStyle />

      <StylesProvider injectFirst>
        <ThemeProvider theme={muiTheme}>
          <SnackbarProvider
            ref={notistackRef}
            maxSnack={3}
            autoHideDuration={5000}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            action={key => (
              <Button color="inherit" onClick={handleSnackbarDismiss(key)}>
                Fermer
              </Button>
            )}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
              <QueryClientProvider client={queryClient}>
                <ConfirmDialogProvider>
                  <ApplicationProvider>
                    <UserProvider>
                      <GlobalDataProvider>
                        <PartnerProvider activityType={ONLINE_ACTIVITY}>
                          <PartnerProvider activityType={OFFLINE_ACTIVITY}>
                            <UserConsumer>
                              {({
                                user,
                                landingPath,
                                temporaryUser,
                                finishedAuthInit,
                                hasOneOfRequiredRoles,
                              }) =>
                                finishedAuthInit && (
                                  <Suspense fallback={<LinearProgress color="primary" />}>
                                    {!!user && (
                                      <Router id="router">
                                        <Layout path="/">
                                          <Home path="/" />
                                          <Mea path="mea/*" />
                                          <Ebon path="ebon/*" />
                                          <Export path="export" />
                                          <Backlog path="backlog" />
                                          <Member path="member/*" />
                                          <Tracking path="tracking" />
                                          <Purchase path="purchase" />
                                          <Dashboard path="dashboard" />
                                          <EbonOrder path="ebon-order" />
                                          <AddPurchase path="add-purchase" />
                                          <CashbackRate path="cashback-rate" />
                                          <PlatformClaim path="platform-claim" />
                                          <PictureUpload path="picture-upload" />
                                          <BolPlatformPurchaseImport path="bol-platform-purchase-import" />
                                          <Communication path="communication/*" />
                                          <PaymentRequest path="payment-request" />
                                          <PaymentRequestValidation path="payment-request-validation" />
                                          <OnlinePartner path="partner/online/*" />
                                          <OfflinePartner path="partner/offline/*" />
                                          <FlashCashHistory path="flashcash-history" />
                                          <DailyNewsGallery path="daily-news/gallery" />
                                          <EbonOrderCreation path="ebon-order-creation/*" />
                                          <PurchaseMassUpdate path="purchase-mass-update" />
                                          <JackpotNotifications path="jackpot-ebon-api-notification" />
                                          <DailyNewsPartnerTop path="daily-news/partner-top" />
                                          <ToolbarNotification path="toolbar/notification/*" />
                                          <AccountingInformation path="accounting-information/*" />
                                          <OfflinePartnerCreation path="offline-partner-creation" />
                                          <EbuyclubOfferComparison path="offer/ebuyclub-comparison" />
                                          <ApplicationClientRequest path="application-client-request" />
                                          <DailyNewsCurrentSelection path="daily-news/current-selection" />
                                          <PartnerExclusiveScheduling path="partner-exclusive-scheduling/*" />
                                          <CloseCustomerServiceMemberRequest path="close-customer-service-member-request" />
                                          <CatalinaReward path="catalina-reward" />
                                          <PartnerNameMatching path="/partner-name-matching" />
                                          <Gallery path="gallery" />
                                          <Gallery path="gallery/partner" />
                                          <EbonAdministration path="ebon-administration" />
                                          <Faqs path="faqs" />
                                          <Invoicing path="invoicing" />
                                          <ECard path="ebon-order-creation-ebuycard" />
                                          <EbonBatchOrder path="ebon-batch-order" />
                                          <AlertRecipient path="alert-recipient" />
                                          <Review path="review" />
                                          <MultiApplication path="multi">
                                            <Home path="/" />
                                            <Offer path="offer/*" />
                                            <PartnerGlobal path="partner/*" />

                                            <PlatformClaim path="platform-claim" />
                                            <EbonApiStock path="ebon-api-stock/*" />
                                            <EbonGroupAdministration path="ebon/*" />

                                            <CommissionBoost path="commission-boost" />
                                            <EbonGlobal path="ebon-global/*" />
                                            <OnlinePartnerCreation path="partner-creation/*" />
                                            <CompetitiveIntelligence path="competitor/*" />
                                            <MeaMultipleImageUpdate path="mea-image-update" />
                                            <Coupon path="coupon" />
                                          </MultiApplication>

                                          <NotFound path="404" />

                                          <RedirectRoute
                                            path="login"
                                            to={
                                              landingPath === "/" &&
                                              hasOneOfRequiredRoles([PMI, PMI_GET]) &&
                                              !user.applicationIds.includes(-1)
                                                ? "/dashboard"
                                                : landingPath
                                            }
                                          />

                                          <RedirectRoute path="password-update" to="/login" />

                                          <RedirectRoute to="/404" default />
                                        </Layout>
                                      </Router>
                                    )}

                                    {!!temporaryUser && (
                                      <Router id="router">
                                        <PasswordUpdate path="password-update" />

                                        <RedirectRoute to="/password-update" default />
                                      </Router>
                                    )}

                                    {!user && !temporaryUser && (
                                      <Router id="router">
                                        <Login path="login" />

                                        <RedirectRoute to="/login" default />
                                      </Router>
                                    )}
                                  </Suspense>
                                )
                              }
                            </UserConsumer>
                          </PartnerProvider>
                        </PartnerProvider>
                      </GlobalDataProvider>
                    </UserProvider>
                  </ApplicationProvider>
                </ConfirmDialogProvider>
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </MuiPickersUtilsProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </StylesProvider>
    </>
  );
}

export default App;
