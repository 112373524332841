import { createContext } from "react";

import { OFFLINE_ACTIVITY, ONLINE_ACTIVITY } from "@constants/cashback";

export const OnlinePartnerContext = createContext();
export const OnlinePartnerConsumer = OnlinePartnerContext.Consumer;

export const OfflinePartnerContext = createContext();
export const OfflinePartnerConsumer = OfflinePartnerContext.Consumer;

export const CONFIG_BY_ACTIVITY_TYPE = {
  [ONLINE_ACTIVITY]: {
    endpoint: "/partner/minimal-partner",
    Context: OnlinePartnerContext,
  },
  [OFFLINE_ACTIVITY]: {
    endpoint: "/offline-partner",
    Context: OfflinePartnerContext,
  },
};
